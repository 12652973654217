<template>
  <div class="blind-box-wrap">
    <div class="blind-box">
      <div class="winning-record">
        <div class="title">中奖纪录</div>
        <div class="content">
          <h3>{{ message }}</h3>
        </div>
      </div>
      <!-- 确定盲盒 -->
      <template v-if="userInfo">
        <a
          href="javascript:;"
          class="btn"
          @click="open"
          v-if="userInfo.IsLuckDraw || userInfo.GameTime >= 600"
        >
          确定开盒
        </a>
        <div class="tip-box" v-else>
          再玩{{ 10 - Math.ceil(userInfo.GameTime / 60) }}小时游戏可开盒
          <p>（满足时间后请重新登录）</p>
        </div>
      </template>

      <div class="game-box">
        <a
          href="javascript:;"
          class="his-list"
          v-if="userInfo && userInfo.Phone"
          @click="showHisRecords(userInfo.Phone)"
          >我的开盒记录<i class="el-icon-d-arrow-right"></i
        ></a>
        <el-row :gutter="15" class="item-box">
          <el-col :span="4" v-for="(item, index) in gameList" :key="index">
            <div class="item">
              <div class="icon">{{ item.percentage }}%</div>
              <img :src="item.url" alt="" />
              <p>{{ item.gameName }}</p>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="rule">
        <div class="content">
          <h3>活动规则：</h3>
          <p>
            用户登录后，累计15天内的游戏时长到达10个小时后，可获得一次抽取盲盒的机会，每天可以抽取一次盲盒，抽取后加平台微信客服领取对应的奖励。本活动的最终解释权归本平台所有。
          </p>
        </div>
      </div>

      <div class="wx-box">
        <div class="content">
          <h3>领奖客服微信</h3>
          <img
            src="@/assets/images/blindBox/code.png"
            width="80"
            height="80"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 打开盲盒动画 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      custom-class="no-title-dialog"
      :close-on-click-modal="false"
      center
    >
      <div class="blind-box-img">
        <img :src="imgUrl" alt="" />
      </div>
    </el-dialog>
    <!-- 中奖弹窗 -->
    <el-dialog
      :visible.sync="isOpen"
      :show-close="false"
      custom-class="no-title-dialog"
      :close-on-click-modal="false"
      width="708px"
      center
      top="0"
      v-if="JSON.stringify(gameObj) !== '{}'"
    >
      <div class="img-box">
        <div class="icon-box">{{ gameObj.percentage }}%</div>
        <div class="game-img-box">
          <img :src="gameObj.url" alt="" />
        </div>
        <div class="game-name">{{ gameObj.gameName }}</div>
        <div class="code-box">
          <img
            src="@/assets/images/blindBox/code.png"
            width="130"
            height="130"
            alt=""
          />
        </div>
      </div>
      <a href="javascript:;" class="btn-confirm" @click="hideDialog">
        <img src="@/assets/images/blindBox/ann.png" alt="" />
      </a>
    </el-dialog>
    <!-- 暂无盲盒 -->
    <el-dialog
      title="温馨提示："
      :visible.sync="errorMessage"
      width="400px"
      center
      custom-class="error-blindBox-dialog"
      :close-on-click-modal="false"
    >
      <span class="text-content">
        近15天累积游戏时长不足10小时或者今天已参与
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toHome">玩游戏, 攒时长</el-button>
      </span>
    </el-dialog>

    <!-- 历史开盒记录 -->
    <el-dialog
      title="历史开盒记录"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-table :data="gridData" height="240" border>
        <template slot="empty">
          <div>你暂时还没有开盒记录，先去玩游戏攒时长吧</div>
        </template>
        <el-table-column
          property="CreateTime"
          label="开盒日期"
        ></el-table-column>
        <el-table-column property="PrizeName" label="奖品"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getConsumerLuckDrawLog, consumerLuckDraw } from "@/api";
export default {
  computed: {
    ...mapGetters(["isLogin", "userInfo", "getIsAuth"]),
  },
  data() {
    return {
      gridData: [],
      dialogTableVisible: false,
      imgUrl: require("@/assets/images/blindBox/open.gif"),
      dialogVisible: false,
      isOpen: false,
      errorMessage: false,
      message: "",
      timer: null,
      gameObj: {},
      gameList: [
        {
          gameName: "绝地求生",
          percentage: 3,
          url: require("@/assets/images/blindBox/1.jpg"),
        },
        {
          gameName: "永劫无间",
          percentage: 3,
          url: require("@/assets/images/blindBox/2.jpg"),
        },
        {
          gameName: "赛博朋克2077",
          percentage: 2,
          url: require("@/assets/images/blindBox/3.jpg"),
        },
        {
          gameName: "雷神加速器cdk",
          percentage: 20,
          url: require("@/assets/images/blindBox/4.jpg"),
        },
        {
          gameName: "糖豆人",
          percentage: 6,
          url: require("@/assets/images/blindBox/5.jpg"),
        },
        {
          gameName: "人类一败涂地",
          percentage: 6,
          url: require("@/assets/images/blindBox/6.jpg"),
        },
        {
          gameName: "租号一天会员",
          percentage: 20,
          url: require("@/assets/images/blindBox/8.jpg"),
        },
        {
          gameName: "steam喜加一",
          percentage: 25,
          url: require("@/assets/images/blindBox/7.jpg"),
        },
        {
          gameName: "生化危机8",
          percentage: 2,
          url: require("@/assets/images/blindBox/9.jpg"),
        },
        {
          gameName: "只狼：影逝二度",
          percentage: 3,
          url: require("@/assets/images/blindBox/10.jpg"),
        },
        {
          gameName: "太空狼人杀",
          percentage: 5,
          url: require("@/assets/images/blindBox/11.jpg"),
        },
        {
          gameName: "碧蓝航线：交汇的航迹",
          percentage: 5,
          url: require("@/assets/images/blindBox/12.jpg"),
        },
      ],
      index: -1,
    };
  },
  created() {
    this._getConsumerLuckDrawLog();
  },
  mounted() {
    this.show();
  },
  destroyed() {
    this.stop();
  },
  methods: {
    show() {
      if (this.timer != null) return;
      this.timer = setInterval(() => {
        //获取到头的第一个字符
        let start = this.message.substring(0, 1);
        //获取到后面的所有字符
        let end = this.message.substring(1);
        //重新拼接得到新的字符串，并赋值给this.message
        this.message = end + start;
      }, 400);
    },
    stop() {
      //清除定时器
      clearInterval(this.timer);
      //清除定时器之后，需要重新将定时器置为null
      this.timer = null;
    },
    open() {
      if (this.isLogin && this.userInfo) {
        let phone = sessionStorage.getItem("Phone");
        if (phone) {
          if (this.getIsAuth && !this.userInfo.IDCard) {
            eventBus.$emit("showAuth");
            return false;
          }
          if (this.userInfo.IsLuckDraw) {
            this.imgUrl += `?v=${new Date().getTime()}`;
            this.dialogVisible = true;
            let _this = this;
            setTimeout(() => {
              _this.dialogVisible = false;
              _this.random();
              _this.isOpen = true;
            }, 3000);
          } else {
            this.errorMessage = true;
          }
        } else {
          eventBus.$emit("showLoginPhone");
        }
      } else {
        eventBus.$emit("getQrCode");
      }
    },
    hideDialog() {
      this.isOpen = false;
    },
    // 去玩游戏
    toHome() {
      this.errorMessage = false;
      this.$router.push("/home");
    },
    // 随机抽奖
    random() {
      let numdsf = Math.floor(Math.random() * (1 - 100) + 100);
      let index = -1;
      if (numdsf >= 1 && numdsf <= 35) {
        index = 6;
      } else if (numdsf >= 36 && numdsf <= 70) {
        index = 7;
      } else if (numdsf >= 71 && numdsf <= 96) {
        index = 3;
      } else if (numdsf == 97) {
        index = 4;
      } else if (numdsf == 98) {
        index = 5;
      } else if (numdsf == 99) {
        index = 10;
      } else if (numdsf == 100) {
        index = 11;
      }
      this.gameObj = this.gameList[index];
      if (JSON.stringify(this.gameObj) !== "{}") {
        this._consumerLuckDraw(this.gameObj.gameName);
      }
    },
    // 盲盒抽奖中奖名单
    async _getConsumerLuckDrawLog() {
      let params = {
        random: Math.floor(Math.random() * (1 - 100) + 100),
      };
      let res = await getConsumerLuckDrawLog(params);
      if (res.StatusCode == 200) {
        if (res.Object.length) {
          let str = "";
          res.Object.map((item) => {
            str +=
              " " +
              item.Phone.replace(item.Phone.substring(3, 7), "****") +
              "于" +
              item.CreateTime.substr(0, 10) +
              "开出" +
              item.PrizeName +
              "；";
          });
          this.message = str;
        }
      }
    },
    async showHisRecords(phone) {
      if (phone) {
        let params = {
          random: Math.floor(Math.random() * (1 - 100) + 100),
          phone: phone,
        };
        let res = await getConsumerLuckDrawLog(params);
        if (res.StatusCode == 200) {
          this.dialogTableVisible = true;
          this.gridData = res.Object;
        }
      }
    },
    // 提交盲盒开奖结果
    async _consumerLuckDraw(prizeName) {
      if (this.userInfo) {
        let params = {
          consumerId: this.userInfo.ConsumerId,
          prizeName: prizeName,
        };
        let res = await consumerLuckDraw(params);
        if (res.StatusCode == 200) {
          this.$store.dispatch("_GetUserInfo");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blind-box-wrap {
  width: 1040px;
  height: 676px;
  padding: 20px;
  .blind-box {
    height: 100%;
    background: url("./../assets/images/blindBox/bg.jpg") no-repeat;
    background-size: 100%;
    position: relative;
    .winning-record {
      width: 100%;
      display: flex;
      height: 50px;
      position: absolute;
      top: 0;
      left: 0;
      .title {
        width: 110px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        background: #a61761;
        color: $white-col;
      }
      .content {
        display: flex;
        flex: 1;
        padding: 0 20px;
        background: rgba(12, 6, 63, 0.5);
        opacity: 0.5;
        overflow: hidden;
        h3 {
          line-height: 50px;
          white-space: nowrap;
          font-size: 16px;
          color: $white-col;
        }
      }
    }
    .btn {
      width: 422px;
      height: 67px;
      line-height: 67px;
      text-align: center;
      letter-spacing: 3px;
      font-size: 28px;
      font-weight: 500;
      position: absolute;
      top: 290px;
      left: 50%;
      margin-left: -211px;
      background: url("./../assets/images/blindBox/btn.png");
      background-size: 100% 100%;
      color: $white-col;
      &:hover {
        transform: translateY(-5px);
      }
    }
    .tip-box {
      width: 422px;
      height: 67px;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      position: absolute;
      top: 290px;
      left: 50%;
      margin-left: -211px;
      color: $white-col;
      line-height: 30px;
      padding-top: 10px;
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .game-box {
      width: 972px;
      height: 279px;
      padding: 10px 20px;
      position: absolute;
      left: 12px;
      bottom: 10px;
      background: url("./../assets/images/blindBox/game.jpg") no-repeat;
      background-size: 100%;
      display: flex;
      .his-list {
        color: #cfcaf2;
        display: flex;
        position: absolute;
        left: 20px;
        bottom: 260px;
        font-size: 18px;
        font-weight: bold;
        // text-decoration: underline;
        i {
          margin-left: 8px;
        }
      }
      .item-box {
        margin-top: 60px;
        .item {
          margin: 8px 0;
          position: relative;
          color: #cfcaf2;
          line-height: 30px;
          text-align: center;
          .icon {
            width: 39px;
            height: 17px;
            line-height: 17px;
            background: #448dfc;
            color: $white-col;
            text-align: center;
            border-radius: 0px 0px 12px 0px;
            position: absolute;
            top: 0;
            left: 0;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }

  .rule {
    width: 168px;
    position: absolute;
    top: 54px;
    right: 15px;
    border: 1px solid;
    border-image: linear-gradient(90deg, #319fe5, #a61761) 10 10;
    background: linear-gradient(90deg, #319fe5 0%, #a61761 100%);
    overflow: hidden;
    box-sizing: border-box;
    .content {
      padding: 12px;
      background: #170e5f;
      color: $white-col;
      line-height: 20px;
      h3 {
        margin-bottom: 2px;
        font-size: 15px;
        color: #3dc6ec;
      }
    }
  }
  .wx-box {
    width: 108px;
    position: absolute;
    top: 294px;
    right: 15px;
    border: 1px solid;
    border-image: linear-gradient(90deg, #319fe5, #a61761) 10 10;
    background: linear-gradient(90deg, #319fe5 0%, #a61761 100%);
    overflow: hidden;
    box-sizing: border-box;
    .content {
      padding: 12px;
      background: #170e5f;
      color: $white-col;
      line-height: 20px;
      text-align: center;
      h3 {
        font-size: 12px;
      }
    }
  }
}
</style>
<style lang="scss">
.no-title-dialog {
  overflow: hidden;
  .blind-box-img {
    text-align: center;
  }
  .img-box {
    width: 708px;
    height: 684px;
    background: url("./../assets/images/blindBox/1.png") no-repeat;
    background-size: 100%;
    position: relative;
    .icon-box {
      width: 84px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      position: absolute;
      top: 134px;
      left: 100px;
      background: url("./../assets/images/blindBox/1-2.png") no-repeat;
      z-index: 99;
      font-size: 16px;
      font-weight: bold;
      @include font_col3();
    }
    .game-img-box {
      width: 441px;
      height: 192px;
      line-height: 42px;
      text-align: center;
      position: absolute;
      top: 151px;
      left: 118px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .game-name {
      width: 441px;
      height: 30px;
      line-height: 30px;
      position: absolute;
      top: 354px;
      left: 118px;
      text-align: center;
      font-size: 22px;
      @include font_col3();
    }
    .code-box {
      width: 130px;
      height: 130px;
      position: absolute;
      left: 50%;
      bottom: 50px;
      margin-left: -65px;
    }
  }
  .btn-confirm {
    width: 202px;
    height: 52px;
    display: block;
    margin: 0 auto;
  }
}
</style>
